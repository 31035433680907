<template>
  <v-row justify="center">
    <v-dialog v-model="dialog.active" persistent width="600">
      <v-card>
        <v-card-title class="headline"> Checklists</v-card-title>
        <v-card class="message-viewer--size" outlined>
          <template>
            <v-list-item-group v-model="selected" multiple>
              <div v-for="(checklist, index) in checklists" :key="index">
                <v-list-item
                  :id="checklist.uuid"
                  color="primary"
                  v-intersect.quiet="{
                    handler: infiniteScrolling,
                  }"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        color="primary"
                        :input-value="active"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ checklist.checklists[0].name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < checklists.length"
                  :key="index"
                ></v-divider>
              </div>
            </v-list-item-group>
          </template>
        </v-card>
        <v-card-actions>
          <v-btn text @click="close">
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="create">
            Add New
          </v-btn>
          <v-btn color="primary" text @click="add">
            Add Selected
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "@/utils/api";
export default {
  props: {
    dialog: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      selected: [],
      checklists: [],
      loading: true,
      getUuid: null,
      nextUrl: null,
    };
  },
  created() {
    this.checklists = this.dialog.response.data.results;
    this.getNextPage(this.dialog.response);
  },
  methods: {
    getNextPage(resp) {
      if (resp.data.next) {
        this.getUuid = resp.data.results[resp.data.results.length - 5].uuid;
        this.nextUrl = resp.data.next;
      } else {
        this.getUuid = null;
      }
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (entries[0].target.id === this.getUuid) {
        let path = this.nextUrl
          .split("/")
          .splice(5, 5)
          .join("/");

        api.getTemplateNextPage(path).then((resp) => {
          this.getNextPage(resp);
          resp.data.results.forEach((element) => {
            this.checklists.push(element);
          });
        });
      }
    },

    // loadTemplates() {
    //   api.getTemplateList().then((resp) => {
    //     this.checklists = resp.data.results;
    //     this.loading = false;
    //     this.getNextPage(resp);
    //   });
    // },

    getTemplateName(item) {
      if (item.checklists.length === 0) {
        return "";
      }
      return item.checklists[0].name;
    },

    add() {
      let selected = this.checklists.filter((element, index) => {
        return this.selected.includes(index);
      });
      this.$emit("add", selected);
    },
    create() {
      this.$emit("create", true);
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
.message-viewer--size {
  overflow-y: scroll;
  min-height: 100px;
  max-height: 400px;
}
</style>
